<template>
  <v-card>
    <v-card-title v-if="id">Изменить институт</v-card-title>
    <v-card-title v-else>Добавить институт</v-card-title>
    <v-card-text>
      <v-text-field v-model="name" placeholder="Название института"/>
      <v-btn v-if="id" :loading="loading" :disabled="name.length < 3" color="primary" outlined rounded width="100%" @click="store">
        Сохранить
      </v-btn>
      <v-btn v-else :loading="loading" :disabled="name.length < 3" color="primary" outlined rounded width="100%" @click="store">
        Добавить
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "CreateInstituteModal",
  props: ['institute'],
  data() {
    return {
      id: null,
      name: '',
      loading: false
    }
  },
  methods: {
    store() {
      this.loading = true;
      const actionName = this.id ? 'updateInstitute': 'createInstitute';
      this.$store.dispatch(actionName, {name: this.name, id: this.id}).then(() => {
        this.$emit('close');
        if (!this.id) {
          this.name = '';
        }
      }).finally(() => {
        this.loading = false;
      })
    }
  },
  mounted() {
    if(this.institute){
      this.name = this.institute.name;
      this.id = this.institute.id;
    }
  }
}
</script>

<style scoped>

</style>
