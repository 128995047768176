<template>
  <v-card>
    <v-card-title v-if="id">Изменить подразделение</v-card-title>
    <v-card-title v-else>Добавить подразделение</v-card-title>
    <v-card-text>
      <v-text-field v-model="name" placeholder="Название подразделения"/>
      <v-btn :loading="loading" :disabled="name.length < 3" color="primary" outlined rounded width="100%" @click="store">
        <span v-if="id">
          Сохранить
        </span>
        <span v-else>
          Добавить
        </span>
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "CreateDepartmentModal",
  data() {
    return {
      name: '',
      loading: false,
      id: null,
    }
  },
  props: ['institute_id', 'department'],
  methods: {
    store() {
      this.loading = true;
      const action = this.id ? 'updateDepartment': 'createDepartment';
      this.$store.dispatch(action, {name: this.name, id: this.id, institute_id: this.institute_id}).then(() => {
        this.$emit('close');
        this.name = '';
      }).finally(() => {
        this.loading = false;
      })
    }
  },
  mounted() {
    if(this.department){
      this.id = this.department.id;
      this.name = this.department.name;
    }
  }
}
</script>

<style scoped>

</style>
