<template>
  <div>
    <v-dialog v-model="createDepartmentModal" max-width="500">
      <CreateDepartmentModal :institute_id="institute_id" @close="createDepartmentModal = false"/>
    </v-dialog>
    <v-btn v-if="$store.getters.checkPermission('can_add_department')" @click="createDepartmentModal = true"
           class="my-2" color="green" outlined rounded small>Добавить подразделение / кафедру
    </v-btn>
    <v-btn rounded class="ml-2" @click.prevent.native="() => $store.dispatch('deleteInstitute', {id: institute_id})" v-if="$store.getters.checkPermission('can_add_department')"  small title="Если" :disabled="departments && !!departments.length" >
      <v-icon left color="red">mdi-delete-outline</v-icon>
      Удалить институт
    </v-btn>

    <v-list>
      <h4>Подразделения:</h4>
      <div v-if="departments.length > 0">
        <v-list-item v-for="department in departments" :key="department.id">
          <v-list-item-title>
            {{ department.name }}
          </v-list-item-title>
          <v-list-item-action v-if="$store.getters.checkPermission('can_add_department')">
            <v-dialog max-width="500">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" small icon>
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <CreateDepartmentModal :institute_id="institute_id" :department="department"/>
            </v-dialog>

          </v-list-item-action>
          <v-list-item-action v-if="$store.getters.checkPermission('can_add_department')">
            <v-btn @click="$store.dispatch('deleteDepartment', {id: department.id})" color="red" small icon>
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </div>
      <div v-else class="my-2">
        Нет подразделений
      </div>
    </v-list>
  </div>
</template>

<script>
import CreateDepartmentModal from "@/components/Admin/Departments/CreateDepartmentModal.vue";

export default {
  name: "InstituteComponent",
  components: {CreateDepartmentModal},
  props: ['institute_id'],
  data() {
    return {
      createDepartmentModal: false
    }
  },
  computed: {
    departments() {
      return this.$store.getters.getDepartmentsByInstituteID(this.institute_id);
    }
  }
}
</script>

<style scoped>

</style>
