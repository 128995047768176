<template>
  <div>
    <div v-if="$store.getters.checkPermission('can_add_department')" class="d-flex align-center justify-center">
      <v-dialog max-width="500" v-model="createInstituteModal">
        <CreateInstituteModal @close="createInstituteModal = false"/>
      </v-dialog>
      <v-btn @click="createInstituteModal = true" class="my-2" outlined rounded color="green">Добавить институт</v-btn>
    </div>

    <v-expansion-panels  outline accordion>
      <v-expansion-panel
          v-for="(item,i) in institutes"
          :key="i"
      >
        <v-expansion-panel-header>
          <div class="title">
            {{ item.name }}
            <v-dialog v-if="$store.getters.checkPermission('can_add_department')"  max-width="500">
              <template v-slot:activator="{ on }">
                <v-btn class="ml-3" small v-on="on" icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <CreateInstituteModal :institute="item"/>
            </v-dialog>

          </div>
          <v-spacer/>

        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <InstituteComponent :institute_id="item.id"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import CreateInstituteModal from '@/components/Admin/Departments/CreateInstituteModal.vue';
import InstituteComponent from '@/components/Admin/Departments/InstituteComponent.vue';

export default {
  name: "AdminDepartmentsComponent",
  components: {InstituteComponent, CreateInstituteModal},
  computed: {
    institutes() {
      return this.$store.state.dictionaries.institutes;
    }
  },
  data() {
    return {
      createInstituteModal: false
    }
  }
}
</script>

<style scoped>

</style>
